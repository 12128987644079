import _objectSpread from "/Users/peakchao/Code/Web/apiopen-v2/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import { getCurrentInstance, onMounted, reactive, ref, unref, toRefs } from "vue";
import { Search, Edit, Setting, Delete } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { isAdmin } from "@/util/EnvUtil";
export default {
  name: "Users",
  components: {
    Search: Search,
    Edit: Edit,
    Setting: Setting,
    Delete: Delete
  },
  setup: function setup() {
    var data = reactive({
      loading: false,
      userList: [],
      dialogEdit: false,
      editForm: {
        account: "",
        password: "",
        level: 0
      }
    });
    var pagination = reactive({
      currentPage: 1,
      pageSize: 10,
      total: 0
    });
    var rules = ref({
      account: [{
        required: true,
        message: "账号不能为空...",
        trigger: "blur"
      }, {
        type: "email",
        min: 5,
        max: 30,
        message: "账号必须是5-30位邮箱格式...",
        trigger: ["blur", "change"]
      }],
      password: [{
        type: "string",
        required: true,
        message: "密码不能为空且只允许数字字母组合...",
        pattern: /^[0-9A-Za-z]+$/,
        trigger: ["blur", "change"]
      }, {
        min: 6,
        max: 18,
        message: "密码必须是6-18位...",
        trigger: ["blur", "change"]
      }],
      level: [{
        required: true,
        message: "用户权限..."
      }]
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var search = ref("");
    var createUserRef = ref();

    var getUserList = function getUserList() {
      data.loading = true;
      proxy.$axios.get("/api/users?page=".concat(pagination.currentPage, "&size=").concat(pagination.pageSize)).then(function (res) {
        data.userList = res.result.list;
        pagination.total = res.result.total;
        data.loading = false;
      }).catch(function () {
        data.userList = [];
        data.loading = false;
      });
    };

    var clearVal = function clearVal() {
      search.value = '';
    };

    var searchVal = function searchVal() {
      pagination.currentPage = 1;
      getUserList();
    };

    var closeDialog = function closeDialog() {
      data.dialogEdit = false;
      var form = unref(createUserRef);
      form.resetFields();
    };

    var addUser = function addUser() {
      var form = unref(createUserRef);
      form.validate(function (valid) {
        if (valid) {
          proxy.$axios.post("/api/createUser", data.editForm).then(function (res) {
            ElMessage({
              message: "添加成功!",
              type: "success"
            });
            getUserList();
            data.dialogEdit = false;
            form.resetFields();
          });
        }
      });
    };

    var handleSizeChange = function handleSizeChange(size) {
      pagination.currentPage = 1;
      pagination.pageSize = size;
      getUserList();
    };

    var handleCurrentChange = function handleCurrentChange(page) {
      pagination.currentPage = page;
      getUserList();
    };

    onMounted(function () {
      getUserList();
    });
    return _objectSpread(_objectSpread(_objectSpread({
      isAdmin: isAdmin(),
      createUserRef: createUserRef,
      rules: rules
    }, toRefs(data)), {}, {
      search: search
    }, toRefs(pagination)), {}, {
      Edit: Edit,
      Setting: Setting,
      Delete: Delete,
      clearVal: clearVal,
      searchVal: searchVal,
      closeDialog: closeDialog,
      addUser: addUser,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange
    });
  }
};